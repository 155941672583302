import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppNavbar from "./components/Navbar";
import Footer from "./components/Footer";
import Processes from "./components/Processes";

function App() {
  return (
      <div className="wrapper">
        <AppNavbar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Processes />}/>
            {/*<Route path="/dashboard" element={<Dashboard auth={auth} setAuth={setAuth}/>}/>*/}
            {/*<Route path="/preferences" element={<Preferences />}/>*/}
            {/*<Route path="/admin" element={<Admin auth={auth}/>}/>*/}
            {/*<Route path="/documentation" element={<Documentation />}/>*/}
            {/*<Route path="/analytics" element={<Analytics />}/>*/}
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
  );
}

export default App;
