
export default function Footer(){
    return (
        <section>
            <footer className={'text-center'} style={{'backgroundColor': '#a4c8ec'}}>
                <div className={'text-center p-3'}>

                </div>

                <div className={'text-center p-3'}>
                    <p>© 2023 Beaver</p>
                </div>

            </footer>

        </section>

    )
}